<template>
  <div class="editText">
    <div class="title">编辑文字</div>
    <div class="header">
      <div class="back" @click="clickBack">
        <img src="@/assets/images/editText/back.png" alt="" />
        <span>返回编辑</span>
      </div>
    </div>
    <div class="main">
      <div class="input">
        <el-input
          v-model="content"
          type="textarea"
          :autosize="{ minRows: 7 }"
          resize="none"
          :style="getStyle()"
        />
      </div>
      <div class="attr">
        <div class="fontFamily">
          <div class="label">
            <img src="@/assets/images/editText/fontFamily.png" alt="" />
            <span>字体</span>
          </div>
          <div class="picker" style="font-size: 14px" @click="openDrawer">
            {{ fontFamily }} >
          </div>
        </div>
        <div class="color">
          <div class="label">
            <img src="@/assets/images/editText/colorPicker.png" alt="" />
            <span>字体颜色</span>
          </div>
          <div class="picker">
            <el-color-picker
              v-model="fontColor"
              size="small"
              @change="changeColor"
            >
            </el-color-picker>
          </div>
        </div>
        <div class="fontSize">
          <div class="label">
            <img src="@/assets/images/editText/fontSize.png" alt="" />
            <span>字体大小</span>
          </div>
          <div class="picker">
            <el-input-number
              v-model="fontSize"
              @change="changeSize"
              :min="10"
              :max="32"
              size="mini"
            ></el-input-number>
          </div>
        </div>
        <div class="lineHeight">
          <div class="label">
            <img src="@/assets/images/editText/lineHeight.png" alt="" />
            <span>行间距</span>
          </div>
          <div class="picker">
            <el-input-number
              v-model="fontLineHeight"
              @change="changeLineHeight"
              :min="10"
              :max="32"
              size="mini"
            ></el-input-number>
          </div>
        </div>
        <div class="textSpacing">
          <div class="label">
            <img src="@/assets/images/editText/textSpacing.png" alt="" />
            <span>字符间距</span>
          </div>
          <div class="picker">
            <el-input-number
              v-model="fontSpacing"
              @change="changeSpacing"
              :min="2"
              :max="16"
              size="mini"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="reset" @click="reset">还原</div>
        <div class="save" @click="save">保存</div>
      </div>
    </div>
    <el-drawer
      :visible.sync="fontFamilyDrawer"
      direction="btt"
      custom-class="fontFamilyDrawer"
      :modal="false"
    >
      <div class="list">
        <div
          class="item"
          v-for="(font, index) in fontList"
          :key="index"
          @click="selectFont(font)"
          :modal="false"
        >
          <div class="example" :style="{ fontFamily: font }">
            <span>字</span>
          </div>
          <div class="title">{{ font }}</div>
        </div>
      </div>
    </el-drawer>
    <van-dialog
      v-model="dialogVisible"
      title="提示"
      show-cancel-button
      closeOnClickOverlay
      message="是否保存本次编辑"
      @confirm="save"
      @cancel="close"
    ></van-dialog>
  </div>
</template>

<script>
export default {
  name: "EditText",
  props: {
    edittingText: {
      type: Object,
    },
  },
  data() {
    return {
      content: "",
      fontColor: "#000",
      fontFamily: "",
      fontFamilyDrawer: false,
      fontSize: 16,
      fontLineHeight: 26,
      fontSpacing: 3,

      dialogVisible: false,

      fontList: [
        "阿里巴巴普惠体",
        "仓耳小丸子",
        "优设好身体",
        "庞门正道轻松体",
        "思源宋体",
        "思源黑体",
        "方正楷体",
        "梅干手写体",
        "清松手写体",
        "站酷快乐体2016",
        "站酷文艺体",
        "HCSZT-Regular",
        "slideyouran-Regular",
        "Yozai-Regular",
        "MasaFont-Regular",
        "jf-openhuninn-1.1",
        "OPPOSans-R",
        "IPix",
      ],
    };
  },
  methods: {
    close() {
      this.dialogVisible = false;
      this.$emit("closeEditText");
    },
    openDrawer() {
      this.fontFamilyDrawer = true;
    },
    clickBack() {
      this.dialogVisible = true;
    },

    // 改变各个属性的回调
    selectFont(font) {
      this.fontFamily = font;
    },
    changeColor() {},
    changeSize() {},
    changeLineHeight() {},
    changeSpacing() {},

    reset() {
      Object.assign(this.$data, this.edittingText);
    },
    save() {
      // 保存操作
      const {
        content,
        fontFamily,
        fontColor,
        fontSize,
        fontLineHeight,
        fontSpacing,
      } = this;
      this.$emit("updateList", {
        type: "text",
        content,
        fontFamily,
        fontColor,
        fontSize,
        fontLineHeight,
        fontSpacing,
      });
      this.dialogVisible = false;
      this.$emit("closeEditText");
    },

    getStyle() {
      return {
        fontFamily: this.fontFamily,
        color: this.fontColor,
        fontSize: this.fontSize + "px",
        lineHeight: this.fontLineHeight + "px",
        letterSpacing: this.fontSpacing + "px",
        height: "100%",
      };
    },
  },
  watch: {
    edittingText: {
      immediate: true,
      handler() {
        Object.assign(this.$data, this.edittingText);
      },
    },
  },
};
</script>

<style lang="scss">
.el-color-dropdown__btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.el-textarea__inner {
  border: 0;
  color: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}
.el-color-dropdown__link-btn {
  display: none;
}
.fontFamilyDrawer {
  width: 100%;
  height: 50vh;
  border-radius: 30px 30px 0 0;
}
.el-drawer__header {
  margin-bottom: 10px;
}
.el-button--primary {
  background: #eb5c69;
  border-color: #eb5c69;
}
</style>

<style lang="scss" scoped>
.editText {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  .title {
    // position: fixed;
    width: 100%;
    height: 31px;
    line-height: 44px;
    text-align: center;
    background-color: #F6F6F6;
  }
  .header {
    width: 90%;
    height: 50px;
    margin: 0 auto;
    padding: 10px 0;
    .back {
      display: flex;
      align-items: center;
      width: 30vw;
      img {
        width: 25px;
        margin-right: 10px;
      }
    }
  }

  .main {
    width: 90%;
    margin: 0 auto;
    height: auto;
    background: #fff;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .input {
      width: 90%;
      height: 200px;
      margin: 10px auto;
      border: 1px dashed #ccc;
      border-radius: 8px;
      overflow-y: auto;
    }

    .attr {
      width: 90%;
      margin: 10px auto;

      & > div {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        span {
          font-size: 15px;
          font-weight: bold;
          color: #bbb;
        }

        .label {
          display: flex;
          align-items: center;

          img {
            width: 18px;
            margin-right: 10px;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      & > div {
        width: 100px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        border-radius: 100px;
        text-align: center;
      }

      .reset {
        background: #cbcbcb;
      }

      .save {
        background: #eb5c69;
      }
    }
  }

  .list {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(5, 18%);
    grid-gap: 2.5%;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      .example {
        position: relative;
        width: 100%;
        padding: 50% 0;
        border-radius: 50%;
        background: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: auto;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .title {
        width: 100%;
        font-size: 12px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 5px;
        background-color: transparent;
      }
    }
  }
}
</style>