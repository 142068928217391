<template>
  <div class="edit">
    <div class="musicPage" v-if="showEditMusic">
      <EditMusic @closeEditMusic="closeEditMusic" :music="music"></EditMusic>
    </div>
    <div class="editTextAndImg" v-show="!showEditMusic">
      <div class="main">
        <div class="back" @click="showTip = true">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          class="show"
          :class="{ preview }"
          :style="{
            height: type ? '70%' : '95%',
            alignSelf: type ? 'center' : 'flex-end',
          }"
        >
          <div class="arrow-up" v-show="curr > 1 && type && !preview"></div>
          <div class="arrow-down" v-show="curr < 8 && type && !preview"></div>
          <div class="process" v-show="type">{{ curr }}/8</div>
          <component
            v-if="templateType == 'birthday'"
            :is="Birthday"
            :updCurr="updCurr"
            :list="list"
            :preview="preview"
            ref="letter"
          ></component>
          <component
            v-if="templateType == 'commemoration'"
            :is="Commemoration"
            :updCurr="updCurr"
            :list="list"
            :preview="preview"
            ref="letter"
          ></component>
          <component
            v-if="templateType == 'loveLetter'"
            :is="LoveLetter"
            :list="list"
            :preview="preview"
            ref="letter"
          ></component>
          <component
            v-if="templateType == 'newYear'"
            :is="NewYear"
            :list="list"
            :preview="preview"
            ref="letter"
          ></component>
          <component
            v-if="templateType == 'valentine'"
            :is="Valentine"
            :list="list"
            :preview="preview"
            ref="letter"
          ></component>
        </div>
        <div class="gap">
          <img src="@/assets/icon/swipe.png" alt="" mode="widthFix" />
          <span>左右两侧均可上下滑动</span>
        </div>
        <div class="menu">
          <span class="title">编辑区</span>
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <img
                v-if="item.type == 'img'"
                src="@/assets/icon/camera.png"
                alt=""
                class="icon"
              />
              <div
                v-if="item.type == 'text'"
                class="editText"
                v-text="item.content"
                @click="toggleEditText(item, index)"
              ></div>
              <div
                v-if="item.type == 'img'"
                class="editImg"
                @click="openEditImg(index)"
              >
                <van-uploader :max-count="1" :before-read="beforeRead">
                  <img :src="item.path" alt="" mode="widthFix" />
                </van-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editBottom">
        <div class="music" @click="gotoMusic">
          <img src="@/assets/icon/music.png" alt="" />
          <span>音乐</span>
        </div>
        <div class="save" @click="reserve">
          <img src="@/assets/icon/save.png" alt="" />
          <span>保存</span>
        </div>
        <div class="preview" @click="gotoPreview">
          <img src="@/assets/icon/preview.png" alt="" />
          <span>预览</span>
        </div>
      </div>
      <el-drawer
        :visible.sync="showEditText"
        position="right"
        custom-class="drawer"
        :withHeader="false"
      >
        <EditText
          @closeEditText="toggleEditText"
          :edittingText="edittingText"
          @updateList="updateList"
        ></EditText>
      </el-drawer>
      <div class="cropper-content" v-if="dialogVisible">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false"
            ><i class="iconfont iconcancel01"></i> 取 消</el-button
          >
          <el-button type="primary" @click="finish"
            ><i class="iconfont iconok01"></i> 确 认</el-button
          >
        </div>
      </div>
      <div class="previewMask" :class="{ preview }" @click="exitPreview">
        点击退出预览模式
      </div>
      <van-overlay :show="load" z-index="100">
        <div class="loading" @click.stop>
          <van-loading size="60" vertical text-size="20"
            >Loading...</van-loading
          >
        </div>
      </van-overlay>
      <audio :src="musicPath" autoplay ref="audio"></audio>
      <el-tooltip
        effect="dark"
        content="点击播放音乐"
        placement="bottom-start"
        v-model="tip"
        v-show="preview"
        manual
      >
        <div class="icon-playMusic play" v-if="play" @click="togglePlay"></div>
        <div class="icon-playMusic pause" v-else @click="togglePlay"></div>
      </el-tooltip>
      <van-dialog
        v-model="showTip"
        title="提示"
        show-cancel-button
        closeOnClickOverlay
        message="是否保留本次编辑内容"
        @confirm="onConfirm"
        @cancel="onCancel"
      ></van-dialog>
    </div>
  </div>
</template>

<script>
import EditText from "./editText.vue";
import EditMusic from "./editMusic.vue";
import newYearData from "@/assets/data/newYear";
import birthdayData from "@/assets/data/birthday";
import loveLetterData from "@/assets/data/loveLetter";
import valentineData from "@/assets/data/valentine";
import commemorationData from "@/assets/data/commemoration";
import { Toast } from "vant";
export default {
  name: "Edit",
  data() {
    return {
      Birthday: () => import("@/components/birthday"),
      Commemoration: () => import("@/components/commemoration"),
      LoveLetter: () => import("@/components/loveLetter"),
      NewYear: () => import("@/components/newYear"),
      Valentine: () => import("@/components/valentine"),

      templateType: "valentine",
      type: true,
      curr: 1,

      edittingText: {},
      edittingIndex: -1,

      // 用来显示的list，其中图片路径为http路径
      list: [],
      // 用来更新至cms的list，其中图片路径为cloud路径
      uploadList: [],
      // 记录需要更换路径的索引值
      indexList: [],
      music:
        "cloud://cloud1-7g144xneb715a224.636c-cloud1-7g144xneb715a224-1307109372/music/一口甜-我超喜欢你.mp3",
      preview: false,
      musicPath: "",

      showEditText: false,
      showEditMusic: false,

      dialogVisible: false,
      showTip: false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      load: false,
      play: true,
      tip: false,
    };
  },
  methods: {
    updCurr(val) {
      this.curr = val;
    },
    onConfirm() {
      this.load = true;
      this.$cloud.callFunction({
        name: "router",
        data: {
          url: "setInfo",
          params: {
            UUID: this.$route.params.UUID,
            info: {
              music: this.music,
              type: this.templateType,
              list: this.uploadList,
            },
          },
        },
        success: (res) => {
          console.log(res);
          this.load = false;
          Toast("保存成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        },
        fail: (err) => {
          console.log(err);
        },
      });
    },
    onCancel() {
      this.$router.go(-1);
    },
    gotoMusic() {
      this.showEditMusic = !this.showEditMusic;
      this.$bus.$emit("changeTitle", "选择音乐");
      this.$route.meta.ismusic = true;
      // this.$router.push({
      //   path: "/editMusic",
      //   query: {
      //     music: encodeURI(this.music),
      //   },
      // });
    },
    closeEditMusic() {
      this.showEditMusic = !this.showEditMusic;
      this.$bus.$emit("changeTitle", "编辑情书");
    },
    gotoPreview() {
      this.$route.meta.ispreview = true;
      this.preview = true;
      if (this.music == "") return;
      this.load = true;
      this.$cloud
        .getTempFileURL({
          fileList: [this.music],
        })
        .then((res) => {
          console.log(res.fileList[0].tempFileURL);
          this.musicPath = res.fileList[0].tempFileURL;
          this.$refs.letter.autoScroll();
        });
    },
    exitPreview() {
      this.$route.meta.ispreview = false;
      this.preview = false;
      this.musicPath = "";
      this.$refs.letter.closeAutoScroll();
    },
    toggleEditText(item, index) {
      console.log("toggleEditext");
      this.edittingText = item;
      this.edittingIndex = index;
      this.showEditText = !this.showEditText;
    },
    updateList(item) {
      this.list.splice(this.edittingIndex, 1, item);
      this.uploadList.splice(this.edittingIndex, 1, item);
    },
    openEditImg(index) {
      this.edittingIndex = index;
    },
    beforeRead(file) {
      this.$set(this.option, "fixedNumber", [
        this.list[this.edittingIndex].aspectRatio,
        1,
      ]);
      console.log(this.option.fixedNumber[0]);
      this.getPictureBase64(file).then((res) => {
        this.option.img = res;
        this.dialogVisible = true;
      });
    },
    getPictureBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    finish() {
      this.load = true;
      this.$refs.cropper.getCropBlob((data) => {
        let file = new File([data], `img${this.edittingIndex}.png`, {
          lastModified: new Date(),
        });
        this.fileData = [[file]];
        this.$cloud.uploadFile({
          cloudPath: `images/${
            this.$route.params.UUID
          }/${new Date().getTime()}.png`,
          file: file,
          success: (res) => {
            console.log("上传完成", res);
            this.uploadList[this.edittingIndex].path = res.fileID;
            this.$cloud
              .getTempFileURL({
                fileList: [res.fileID],
              })
              .then((res) => {
                this.load = false;
                console.log("下载完成", res);
                this.$set(
                  this.list,
                  this.edittingIndex,
                  Object.assign(this.list[this.edittingIndex], {
                    path: res.fileList[0].tempFileURL,
                  })
                );
              });
          },
        });
        // this.getPictureBase64(file).then((res) => {
        //   // 第三个属性是宽高比,且高固定为1，因此只需记录宽即可
        //   this.uploadList.splice(this.edittingIndex, 1, {
        //     type: "img",
        //     path: res,
        //     aspectRatio: this.option.fixedNumber[0],
        //   });
        // });
      });
      this.dialogVisible = false;
    },
    reserve() {
      console.log(this.$route.params.UUID);
      this.load = true;
      this.$cloud.callFunction({
        name: "router",
        data: {
          url: "setInfo",
          params: {
            UUID: this.$route.params.UUID,
            info: {
              music: this.music,
              type: this.templateType,
              list: this.uploadList,
            },
          },
        },
        success: (res) => {
          console.log(res);
          this.load = false;
          Toast("保存成功");
          setTimeout(() => {
            this.$router.push({
              path: `/finished/1/${this.$route.params.UUID}`,
            });
          }, 1000);
        },
        fail: (err) => {
          console.log(err);
        },
      });
    },
    togglePlay() {
      this.play = !this.play;
      if (this.play) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (this.preview) {
        if (document.hidden) {
          this.play = false;
          this.$refs.audio.pause();
        } else {
          setTimeout(() => {
            this.togglePlay();
          }, 1000);
        }
      }
    });

    this.$route.meta.ismusic = false;
    this.$route.meta.ispreview = false;
    this.templateType = this.$route.params.type;
    this.type =
      this.templateType == "birthday" || this.templateType == "commemoration"
        ? true
        : false;
    switch (this.templateType) {
      case "newYear":
        this.list = newYearData;
        break;
      case "birthday":
        this.list = birthdayData;
        break;
      case "commemoration":
        this.list = commemorationData;
        break;
      case "loveLetter":
        this.list = loveLetterData;
        break;
      case "valentine":
        this.list = valentineData;
        break;

      default:
        break;
    }
    if (!+this.$route.params.new) {
      this.load = true;
      this.$cloud
        .callFunction({
          name: "router",
          data: {
            url: "getInfo",
            params: {
              UUID: this.$route.params.UUID,
            },
          },
        })
        .then((res) => {
          console.log(res);
          this.list = res.result.data.list;
          this.music = res.result.data.music;
          this.uploadList = JSON.parse(JSON.stringify(this.list));
          let cloudList = this.list
            .map((item, index) => {
              if (item.path && item.path.startsWith("cloud")) {
                this.indexList.push(index);
                return item.path;
              } else {
                return undefined;
              }
            })
            .filter((item) => item);
          this.$cloud
            .getTempFileURL({
              fileList: cloudList,
            })
            .then((res) => {
              console.log(res);
              this.indexList.forEach((item, index) => {
                this.$set(
                  this.list,
                  item,
                  Object.assign(this.list[item], {
                    path: res.fileList[index].tempFileURL,
                  })
                );
              });
              this.load = false;
            });
        });
    } else {
      this.uploadList = JSON.parse(JSON.stringify(this.list));
    }

    this.$refs.audio.oncanplay = () => {
      this.load = false;
    };

    // 在总线上绑定，用于接收editMusic的数据
    this.$bus.$on("selectSong", (data) => {
      this.music = data;
    });
    // this.$bus.$on("onConfirm", (data) => {
    //   this.load = true;
    //   this.$cloud.callFunction({
    //     name: "router",
    //     data: {
    //       url: "setInfo",
    //       params: {
    //         UUID: this.$route.params.UUID,
    //         info: {
    //           music: this.music,
    //           type: this.templateType,
    //           list: this.uploadList,
    //         },
    //       },
    //     },
    //     success: (res) => {
    //       console.log(res);
    //       this.load = false;
    //       Toast("保存成功");
    //       setTimeout(() => {
    //         this.$router.go(-1);
    //       }, 1000);
    //     },
    //     fail: (err) => {
    //       console.log(err);
    //     },
    //   });
    // });
    this.$bus.$on("returnToEdit", (data) => {
      this.showEditMusic = false;
      this.exitPreview();
    });
  },
  components: { EditText, EditMusic },
};
</script>

<style lang="scss">
// /deep/ .el-drawer__wrapper{
//   height: calc(100vh - 44px);
//   margin-top: 44px;
// }
.drawer {
  width: 100% !important;
  height: 100%;
}
.vue-cropper {
  background-image: none !important;
  background-color: #333333;
}
</style>

<style lang="scss" scoped>
.cropper-content {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 10;
  .cropper {
    width: 100%;
    height: 100%;
  }
  .dialog-footer {
    position: absolute;
    display: flex;
    width: 90%;
    height: 40px;
    justify-content: space-between;
    bottom: 10px;
    left: 5%;
  }
}
.edit {
  width: 100vw;
  height: 100vh;
  .musicPage {
    z-index: 10000;
    width: 100vw;
    height: 100vh;
  }
  .main {
    width: 100%;
    height: calc(100vh - 84px);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 10px;
    background-color: #ededed;
    position: relative;

    .back {
      position: absolute;
      top: 5px;
      left: 5px;

      .el-icon-arrow-left {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .text1 {
      font-size: 14px !important;
    }

    .valentine-border,
    .newYear-border,
    .birthday-border {
      border: 2px solid #eb5c69;
    }

    .loveLetter-border {
      border: 2px solid #ffd4ad;
    }

    .changeHeight {
      height: 19% !important;
    }

    .show {
      width: 60%;
      position: relative;
      transition: 0.3s;
      background: #fff;
      align-self: flex-end;

      &.preview {
        position: fixed;
        z-index: 10;
        width: 100vw;
        height: 100vh !important;
        top: 0;
      }

      .process {
        position: absolute;
        padding: 3px 12px;
        background: #eb5c69;
        z-index: 1;
        color: #fff;
        border-end-end-radius: 20px;
      }

      .arrow-up {
        position: absolute;
        background: url(~@/assets/icon/arrow-up.png);
        background-size: cover;
        width: 50px;
        height: 50px;
        left: 50%;
        top: -60px;
        transform: translateX(-50%);
        animation: fale 2s ease-in-out infinite;
      }

      .arrow-down {
        content: "";
        position: absolute;
        background: url(~@/assets/icon/arrow-down.png);
        background-size: cover;
        width: 50px;
        height: 50px;
        left: 50%;
        bottom: -60px;
        transform: translateX(-50%);
        animation: fale 2s ease-in-out infinite;
      }
    }

    .gap {
      width: 6%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #b2b2b2;
      img {
        width: 90%;
        margin-bottom: 5px;
      }
      span {
        font-size: 14px;
      }
    }

    .menu {
      width: 30%;
      height: 100%;
      text-align: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }

      .title {
        display: block;
        height: 50px;
        line-height: 50px !important;
      }

      .list {
        width: 100%;
        height: calc(100vh - 134px);
        box-sizing: border-box;
        padding: 0 5px;
        overflow-y: auto;

        .item {
          position: relative;
          width: 100%;
          background-color: #fff;
          padding: 50% 0;
          font-size: 13px;
          white-space: pre-line;
          margin: 8px 0;
          overflow: hidden;
          border-radius: 8px;
          display: flex;
          .icon {
            width: 30%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
            pointer-events: none;
          }
          .editText {
            position: absolute;
            top: 0;
            left: 0;
            padding: 4px;
            margin: 0 auto;
          }

          .editImg {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              position: relative;
            }
          }
        }
      }
    }
  }

  .editBottom {
    width: 100%;
    height: 84px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 2;

    & > div {
      width: 8%;
      text-align: center;
      display: flex;
      flex-direction: column;

      span {
        font-size: 14px;
      }

      img {
        width: 80%;
        margin: 5px auto;
      }
    }
  }
}
.previewMask {
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100vw;
  height: 50px;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  animation: fale 2s ease-in-out infinite;
  display: none;

  &.preview {
    display: flex;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fale {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
}
.icon-playMusic {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 8px;
  right: 8px;
  filter: grayscale(1);
  z-index: 99;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid transparent;

  &.play {
    background-image: url("~@/assets/icon/playMusic.png");
    filter: none;
    animation: round 4s linear infinite;
  }

  &.pause {
    background-image: url("~@/assets/icon/pauseMusic.png");
  }
}

@keyframes round {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>